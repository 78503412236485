<template>
  <div class="disclaimer-wrapper pa-4 fs-14 black--text">
    Please note that this is a non-verified product created by a Sourcery user
    and not the manufacturer. We encourage that you verify all information with
    the manufacturer or
    <span
      class="text-decoration-underline pointer"
      @click="$emit('request')">
      request to have this product verified.
    </span>
  </div>
</template>

<script>
export default {
  name: 'RequestVerification',
};
</script>

<style lang="scss" scoped>
.disclaimer-wrapper {
  border-radius: 10px;
  background: rgba(218, 48, 76, 0.10);
  line-height: 150%;
}
</style>